<template>
  <div class="accountDetail">
    <!-- 蓝色卡片部分 -->
    <div class="businessCard">
      <div class="navbar">
        <div
          class="navbar-right"
          @click="outcheck"
        ><span>退出</span>
          <Icon
            name="revoke"
            size="18"
          />
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-center">
          <div class="center-top">
            <div class="box">
              <img
                src="./img/header.png"
                alt=""
              >
            </div>
          </div>
          <div class="center-bottom">
            示例姓名
          </div>
        </div>
      </div>
    </div>
    <!-- 角色，版本 -->
    <Field
      v-model="fieldValue"
      is-link
      readonly
      label="角色"
      placeholder="请选择所在地区"
      @click="show = true"
    />
    <Popup
      v-model="show"
      round
      position="bottom"
    >
      <Cascader
        v-model="cascaderValue"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </Popup>
    <Field
      label="文本"
      value="123"
      readonly
      input-align="right"
    />
  </div>
</template>
<script>
import { Toast, Icon, Popup, Field, Cascader } from 'vant'
import storage from '@/utils/storage'
import './index.less'
export default {
  data () {
    return {
      show: false,
      fieldValue: '',
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{ text: '杭州市', value: '330100' }],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{ text: '南京市', value: '320100' }],
        },
      ],
    }
  },
  methods: {
    outcheck () {
      storage.clear()
      this.$router.push('/login')
    },
    onFinish ({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
    },
  },
  components: {
    Icon,
    Popup,
    Field,
    Cascader
  }
}
</script>
<style lang="less" scoped>
</style>
